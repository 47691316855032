import React from 'react'
import { connect } from 'react-redux'
import { DataTable, Grommet, Text } from 'grommet'
import { deepMerge } from 'grommet/utils'
import { generate } from 'grommet/themes/base'
import { get, isEqual, keys, upperCase, head, map, take } from 'lodash/fp'
import { modernMail } from '../lib/grommetThemes'
import Heading from './Heading'
import styled from 'styled-components'

const mapColumn = key => ({
  property: key,
  header: upperCase(key),
  search: false,
  align: 'start',
})

const theme = deepMerge(generate('10px'), modernMail)

const ContactListPreview = ({ rows, remaining }) => {
  const columns = map(mapColumn, keys(head(rows)))
  return (
    <Grommet theme={theme}>
      {rows && rows.length > 0 ? (
        <>
          <DataTable
            a11yTitle="CSV Upload preview"
            columns={columns}
            data={rows}
            resizable={false}
            sortable={false}
            size="small"
            style={{ fontSize: '10px' }}
          />
          <Text>
            Showing {rows.length} of {rows.length + remaining}{' '}
          </Text>
          <Text size="small">rows ({remaining} not shown)</Text>
        </>
      ) : null}
    </Grommet>
  )
}

const mapStateToProps = (state, props) => {
  return {
    rows: state.order.listPreview,
    remaining: state.order.listRemaining,
  }
}

export default connect(mapStateToProps)(ContactListPreview)
