import React from 'react'
import { Text } from 'grommet'

const FormErrorMessage = ({ error }) => (
  <Text color="status-error" size="small">
    {error}
  </Text>
)

export default FormErrorMessage
