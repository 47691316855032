import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  padding: 0.4em;
  width: 60px;
  border: 1px solid #000;
`

const FormInputNumber = ({ field, form }) => {
  return (
    <StyledInput
      {...field}
      type="number"
      onChange={form.handleChange}
      name={field.name}
    />
  )
}

export default FormInputNumber
