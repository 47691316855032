import orderSetQuantity from './orderSetQuantity'
import orderSetListData from './orderSetListData'
import uiSetLoadingAction from './uiSetLoadingAction'

const getCsvRowInfo = function setProductArt(payload) {
  return async (dispatch, getState) => {
    dispatch(uiSetLoadingAction('csvRowCount'))
    dispatch(orderSetListData({}))
    const res = await fetch(
      `${process.env.GATSBY_UPLOADS_URL}${
        process.env.GATSBY_CSV_ROW_COUNT_PATH
      }`,
      {
        method: 'POST',
        body: JSON.stringify({
          key: payload,
        }),
      }
    )
    const data = await res.json()
    dispatch(orderSetQuantity(data.rowCount))
    dispatch(orderSetListData(data))
    dispatch(uiSetLoadingAction(null))
  }
}

export default getCsvRowInfo
