import {
  find,
  filter,
  get,
  isEqual,
  lowerCase,
  camelCase,
  head,
} from 'lodash/fp'

const ultraMax = 9999999999999999999999999

const isProduct = (x, product) => {
  return isEqual(product, `${lowerCase(get('node.product.name', x))}s`)
}

const isWithinQtyRange = (x, quantity) => {
  const min = parseInt(get('node.attributes.minQuantity', x))
  const max =
    get('node.attributes.maxQuantity', x) === 'none'
      ? ultraMax
      : get('node.attributes.maxQuantity', x)

  return quantity <= max && quantity >= min
}

const isMailType = (x, mailType) => {
  return isEqual(mailType, camelCase(get('node.attributes.mailType', x)))
}

const isSizeMatch = (x, origSku) => {
  const origSize = origSku.split('-')[0].replace(/_/g, '.')
  const size = get('node.attributes.name', x).replace(/ /g, '')
  return isEqual(origSize, size)
}

const determineFinalSku = (skuData, quantity, mailType, origSku, product) => {
  const all = get('allStripeSku.edges', skuData)
  const productMatches = filter(x => isProduct(x, product), all)
  const typeMatches = filter(x => isMailType(x, mailType), productMatches)
  const sizeMatches = isEqual(product, 'letters')
    ? typeMatches
    : filter(x => isSizeMatch(x, origSku), typeMatches)

  const qtyMatches = filter(
    x => isWithinQtyRange(x, parseInt(quantity)),
    sizeMatches
  )

  return {
    id: get('node.id', head(qtyMatches)),
    info: get('node', head(qtyMatches)),
  }
}

export default determineFinalSku
