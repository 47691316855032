import React, { Component } from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import {
  Grid,
  Box,
  Text,
  TextInput,
  RadioButton,
  Layer,
  MaskedInput,
} from 'grommet'
import { graphql, StaticQuery } from 'gatsby'
import { navigate, Link } from 'gatsby'
import { get, find, isEmpty, isEqual, includes } from 'lodash/fp'
import { Close } from 'grommet-icons'
import styled from 'styled-components'

import Loading from './Loading'
import Heading from './Heading'
import Button from './Button'
import ColumnStack from './ColumnStack'
import FormInputNumber from './FormInputNumber'
import ContentContainer from './ContentContainer'
import ResponsiveHidden from './ResponsiveHidden'
import FormField from './FormField'
import FieldSet from './FieldSet'
import FormInputS3File from './FormInputS3File'
import FormInputCheckBox from './FormInputCheckBox'
import FormErrorMessage from './FormErrorMessage'
import ContactListPreview from './ContactListPreview'
import { ChevronUp, ChevronDown } from './Icons'
import orderSetList from '../store/actions/orderSetList'
import orderSetProductSku from '../store/actions/orderSetProductSku'
import getCsvRowInfo from '../store/actions/getCsvRowInfo'
import determineFinalSku from '../lib/determineFinalSku'

const StyledForm = styled(Form)`
  height: 100%;
`

const GET_ALL_SKUS = graphql`
  query GetAllSkus {
    allStripeSku {
      edges {
        node {
          id
          active
          price
          attributes {
            size
            mailType
            minQuantity
            maxQuantity
            name
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`

const NoListText = () => (
  <Box pad={{ vertical: 'small' }}>
    <Text size="small">
      No worries! We’ll give you a call after you complete your order and we can
      get you a list - for free
    </Text>
  </Box>
)

const FormSchema = Yup.object().shape({
  noList: Yup.boolean(),
  contactsList: Yup.string().when('noList', {
    is: true,
    then: Yup.string()
      .nullable()
      .notRequired(),
    otherwise: Yup.string().required('Required'),
  }),
  quantity: Yup.number()
    .required()
    .min(200, 'minimum quantity is 200'),
  mailType: Yup.string().required(),
  npa: Yup.string().when('mailType', {
    is: val => val == 'nonprofit',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
})

class FormContactsList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      viewingCsv: false,
      qtyEntry: !includes(parseInt(props.initialValues.quantity), [
        500,
        1000,
        2500,
        5000,
        10000,
      ]),
    }
  }
  handleListUpload = async (url, s3Key, filename, cb) => {
    const { getCsvRowInfo, orderSetList } = this.props
    orderSetList({ list: url, listFilename: filename, noList: false })
    await getCsvRowInfo(s3Key)
    if (cb && typeof cb === 'function') {
      cb()
    }
  }

  handlePreview = () => {
    this.setState({ viewingCsv: true })
  }

  closePreview = () => {
    this.setState({ viewingCsv: false })
  }

  componentDidMount() {
    const { product, art, productSku } = this.props
    const isLetter = isEqual(product, 'letters')
    const needsBackArt = isLetter ? false : isEmpty(get('back', art))

    if (isEmpty(productSku)) {
      return navigate(`/${product.toLowerCase()}`)
    }
    if (!get('none', art) && (isEmpty(get('front', art)) || needsBackArt)) {
      return navigate(`/${product.toLowerCase()}/design`)
    }
  }
  render() {
    const {
      data,
      product,
      initialValues,
      orderSetProductArt,
      csvLoading,
      orderSetList,
      orderSetProductSku,
      productSku,
    } = this.props

    const isLetter = isEqual(product, 'letters')
    const qtyEntry = this.state.qtyEntry

    return (
      <StaticQuery
        query={GET_ALL_SKUS}
        render={skuData => (
          <Formik
            initialValues={initialValues}
            isInitialValid={() => FormSchema.isValidSync(initialValues)}
            validationSchema={FormSchema}
            enableReinitialize={true}
            onSubmit={async values => {
              const { contactsList, quantity, noList, mailType, npa } = values
              const finalSku = determineFinalSku(
                skuData,
                quantity,
                mailType,
                productSku,
                product
              )
              orderSetProductSku({
                sku: finalSku.id,
                product,
                info: finalSku.info,
              })
              orderSetList({
                list: contactsList,
                listFilename: values['contactsList-filename'],
                noList,
                quantity,
                mailType,
                npa,
              })
              navigate(`/${product.toLowerCase()}/confirm`)
            }}
            render={({
              values,
              isSubmitting,
              isValid,
              handleChange,
              handleBlur,
              validateForm,
              errors,
              dirty,
              touched,
              setFieldValue,
              setFieldTouched,
            }) => {
              const qtyIsntOption = !includes(parseInt(values.quantity), [
                0,
                500,
                1000,
                2500,
                5000,
                10000,
              ])
              return (
                <StyledForm>
                  <ColumnStack gap="medium" fill>
                    <Box fill>
                      <ResponsiveHidden hideAt={['medium', 'large', 'xlarge']}>
                        <Box align="start">
                          <Link
                            to={`/${product}/${
                              isLetter ? 'envelope' : 'design'
                            }`}
                          >
                            <Button
                              label="Previous"
                              icon={<ChevronUp size="small" color="dark-1" />}
                            />
                          </Link>
                        </Box>
                      </ResponsiveHidden>
                      <ContentContainer side="left" justify="center">
                        <Field
                          component={FormInputS3File}
                          disabled={values.noList}
                          label="Mailing List"
                          prompt="Drop a CSV file here"
                          name="contactsList"
                          filename={values['contactsList-filename']}
                          onUploadFinished={(url, s3Key, filename) => {
                            this.handleListUpload(
                              url,
                              s3Key,
                              filename,
                              validateForm
                            )
                          }}
                        />
                      </ContentContainer>
                      <ResponsiveHidden>
                        <Box align="start">
                          <Link
                            to={`/${product}/${
                              isLetter ? 'envelope' : 'design'
                            }`}
                          >
                            <Button
                              label="Previous"
                              icon={<ChevronUp size="small" color="dark-1" />}
                            />
                          </Link>
                        </Box>
                      </ResponsiveHidden>
                    </Box>
                    <Box fill>
                      <ContentContainer justify="center">
                        {values.contactsList ? (
                          <FieldSet>
                            <Text size="small" color="dark-2">
                              Upload Mailing List
                            </Text>
                            <FormField
                              error={errors.quantity}
                              htmlFor="quantity"
                              label="Quantity"
                            >
                              {csvLoading ? (
                                <Box direction="row" align="center">
                                  <Box>
                                    <Loading />
                                  </Box>
                                  <Box pad="small">Counting contacts...</Box>
                                </Box>
                              ) : (
                                <>
                                  <TextInput
                                    id="quantity"
                                    name="quantity"
                                    type="number"
                                    align="right"
                                    readOnly={true}
                                    onChange={handleChange}
                                    value={String(values.quantity)}
                                    onBlur={handleBlur}
                                    style={{ textAlign: 'right' }}
                                    plain
                                  />
                                  {errors.quantity && (
                                    <FormErrorMessage error={errors.quantity} />
                                  )}
                                </>
                              )}
                            </FormField>
                          </FieldSet>
                        ) : (
                          <FieldSet>
                            <Text size="small" color="dark-2">
                              Upload Mailing List
                            </Text>
                            <FormField
                              label="I don't have a List"
                              extra={values.noList && <NoListText />}
                            >
                              <Field
                                component={FormInputCheckBox}
                                name="noList"
                              />
                            </FormField>
                          </FieldSet>
                        )}
                        {values.noList && (
                          <Box justify="center">
                            <FieldSet>
                              <Text size="small" color="dark-2">
                                Select Quantity
                              </Text>
                              <Grid
                                columns={{
                                  count: 2,
                                  size: 'auto',
                                }}
                                gap="xlarge"
                              >
                                <Box>
                                  <FormField
                                    label="500"
                                    htmlFor="fiveHundred"
                                    fill="label"
                                    align="end"
                                  >
                                    <RadioButton
                                      id="fiveHundred"
                                      onChange={handleChange}
                                      name="quantity"
                                      value={500}
                                      checked={
                                        parseInt(values.quantity) === 500
                                      }
                                    />
                                  </FormField>
                                  <FormField
                                    label="1000"
                                    htmlFor="oneThousand"
                                    fill="label"
                                    align="end"
                                  >
                                    <RadioButton
                                      id="oneThousand"
                                      onChange={handleChange}
                                      name="quantity"
                                      value={1000}
                                      checked={
                                        parseInt(values.quantity) === 1000
                                      }
                                    />
                                  </FormField>
                                  <FormField
                                    label="2500"
                                    htmlFor="twoThousandFiveHundred"
                                    fill="label"
                                    align="end"
                                  >
                                    <RadioButton
                                      id="twoThousandFiveHundred"
                                      onChange={handleChange}
                                      name="quantity"
                                      value={2500}
                                      checked={
                                        parseInt(values.quantity) === 2500
                                      }
                                    />
                                  </FormField>
                                </Box>
                                <Box>
                                  <FormField
                                    label="5000"
                                    htmlFor="fiveThousand"
                                    fill="label"
                                    align="end"
                                  >
                                    <RadioButton
                                      id="fiveThousand"
                                      onChange={handleChange}
                                      name="quantity"
                                      value={5000}
                                      checked={
                                        parseInt(values.quantity) === 5000
                                      }
                                    />
                                  </FormField>
                                  <FormField
                                    label="10000"
                                    htmlFor="tenThousand"
                                    fill="label"
                                    align="end"
                                  >
                                    <RadioButton
                                      id="tenThousand"
                                      onChange={handleChange}
                                      name="quantity"
                                      value={10000}
                                      checked={
                                        parseInt(values.quantity) === 10000
                                      }
                                    />
                                  </FormField>
                                  <FormField
                                    label={qtyIsntOption ? '' : 'Other'}
                                    htmlFor="otherQty"
                                    align="end"
                                    fill={qtyEntry ? 'label' : undefined}
                                  >
                                    <Box direction="row" gap="xsmall">
                                      {qtyEntry && qtyIsntOption && (
                                        <Field
                                          component={FormInputNumber}
                                          id="quantity"
                                          name="quantity"
                                          type="number"
                                          align="right"
                                        />
                                      )}
                                      <Box flex="grow">
                                        <RadioButton
                                          id="other"
                                          onChange={e => {
                                            this.setState({
                                              qtyEntry: e.target.value,
                                            })
                                            setFieldTouched(
                                              'otherQuantity',
                                              true
                                            )
                                            setFieldValue('quantity', 25000)
                                          }}
                                          name="otherQuantity"
                                          value={true}
                                          checked={qtyEntry && qtyIsntOption}
                                        />
                                      </Box>
                                    </Box>
                                  </FormField>
                                </Box>
                              </Grid>
                            </FieldSet>
                            <Box>
                              {dirty &&
                                touched.otherQuantity &&
                                errors.quantity && (
                                  <FormErrorMessage error={errors.quantity} />
                                )}
                            </Box>
                          </Box>
                        )}
                        {(values.contactsList || values.noList) && (
                          <FieldSet>
                            <Text size="small" color="dark-2">
                              How do you want to mail them?
                            </Text>
                            <FormField
                              label="First Class"
                              htmlFor="firstClass"
                              fill="label"
                            >
                              <RadioButton
                                id="firstClass"
                                onChange={handleChange}
                                name="mailType"
                                value="firstClass"
                                checked={values.mailType === 'firstClass'}
                              />
                            </FormField>
                            <FormField
                              label="Marketing Mail"
                              htmlFor="marketingMail"
                              fill="label"
                            >
                              <RadioButton
                                id="marketingMail"
                                onChange={handleChange}
                                name="mailType"
                                value="marketingMail"
                                checked={values.mailType === 'marketingMail'}
                              />
                            </FormField>
                            <FormField
                              label="Nonprofit"
                              htmlFor="nonprofit"
                              fill="label"
                            >
                              <RadioButton
                                id="nonprofit"
                                onChange={handleChange}
                                name="mailType"
                                value="nonprofit"
                                checked={values.mailType === 'nonprofit'}
                              />
                            </FormField>
                            {values.mailType === 'nonprofit' && (
                              <FormField
                                htmlFor="npa"
                                label="Nonprofit Authorization #"
                                size="small"
                                fill="label"
                              >
                                <Box pad={{ horizontal: 'small' }}>
                                  <TextInput
                                    id="npa"
                                    name="npa"
                                    align="right"
                                    size="xsmall"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ textAlign: 'right' }}
                                  />
                                </Box>
                              </FormField>
                            )}
                          </FieldSet>
                        )}
                      </ContentContainer>
                      <Box align="end" margin={{ top: 'medium' }}>
                        <Button
                          primary
                          type="submit"
                          disabled={!isValid}
                          label="Next"
                          reverse
                          icon={<ChevronDown size="small" />}
                        />
                      </Box>
                    </Box>
                  </ColumnStack>
                </StyledForm>
              )
            }}
          />
        )}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  csvLoading: state.ui.loadingAction === 'csvRowCount',
  art: state.order.art,
  productSku: state.order.productSku,
  initialValues: {
    contactsList: state.order.list,
    noList: state.order.noList,
    'contactsList-filename': state.order.listFilename,
    quantity: state.order.quantity,
    mailType: 'marketingMail',
    otherQuantity: false,
  },
})

export default connect(
  mapStateToProps,
  { getCsvRowInfo, orderSetList, orderSetProductSku }
)(FormContactsList)
